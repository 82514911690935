import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";

import { Button } from "@/components/ui/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";

const formSchema = z.object({
  zipCode: z.string().length(5, "Zip code must be 5 digits"),
});

export function GetStartedForm() {
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      zipCode: "",
    },
  });

  function onSubmit(values: z.infer<typeof formSchema>) {
    window.localStorage.setItem("zipCode", values.zipCode);
    window.location.href = `/auth/signup?zipCode=${values.zipCode}`;
  }

  return (
    <div>
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
          <FormField
            control={form.control}
            name="zipCode"
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <Input
                    placeholder="Enter Zip Code"
                    {...field}
                    className="rounded-sm"
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <Button type="submit" variant={"default"} className="w-full">
            {" "}
            Get Started{" "}
          </Button>
          <div className="mt-4 text-center">
            Already have an account?{" "}
            <a href="/auth/signin" className="underline">
              Sign in here
            </a>
          </div>
        </form>
      </Form>
    </div>
  );
}
